import React, { useState, useRef } from "react";
import { useParams } from "react-router-dom";
import Form from "react-bootstrap/Form";
import { toast } from "react-toastify";
import Button from "react-bootstrap/Button";
import FloatingLabel from "react-bootstrap/FloatingLabel";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import { Toggle } from "../switch";
import httpRoomServices from "../services/http.room.services";
import EditAddRoomImages from "./edit_add_room_gallery";
import EditAddRoomNumber from "./edit_add_room_numbers";

function EditBranchRooms() {
  const { uid, id } = useParams();
  const [formSubmitting, setFormSubmitting] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const [validated, setValidated] = useState(false);
  const [amenities, setAmenities] = useState([]);
  const [facility, setFacility] = useState(null);
  const [details, setDetails] = useState(null);
  const [formData, setFormData] = useState({
    room_name: details?.room_type,
    room_description: details?.room_description,
    room_type: details?.room_type,
    room_occupation: details?.room_occupation,
    room_price: details?.room_price,
    room_number: details?.room_number,
    inCludeBreakFast: details?.includeBreakFast,
    inCludeLunch: details?.includeLunch,
    inCludeSupper: details?.includeSupper,
    breakFastPrice: details?.breakFastPrice,
    lunchPrice: details?.lunchPrice,
    supperPrice: details?.supperPrice,
  });
  const [breakFast, setBreakFast] = useState(details?.includeBreakFast);
  const [lunch, setLunch] = useState(details?.includeLunch);
  const [supper, setSupper] = useState(details?.includeSupper);

  const onChange = (e) =>
    setFormData({ ...formData, [e.target.name]: e.target.value });

  let tagInput = useRef();
  let aminitytagInput = useRef();
  // room amenities
  const handleAminity = (e) => {
    setFacility(e.target.value);
  };

  const removeAminitiesTag = (i) => {
    const newTags = [...amenities];
    newTags.splice(i, 1);
    setAmenities(newTags);
  };

  const inputAminityKeyDown = (e) => {
    const val = e.target.value;
    if (e.key === "Enter" && val) {
      if (amenities.find((tag) => tag.toLowerCase() === val.toLowerCase())) {
        return;
      }
      setAmenities([...amenities, val]);
      aminitytagInput.value = null;
    } else if (e.key === "Backspace" && !val) {
      removeAminitiesTag(amenities.length - 1);
    }
  };

  /**
   * Stop enter submitting the form.
   * @param keyEvent Event triggered when the user presses a key.
   */
  function onKeyDown(keyEvent) {
    if ((keyEvent.charCode || keyEvent.keyCode) === 13) {
      keyEvent.preventDefault();
    }
  }

  // GET ROOM DETAILS
  const fetchData = async () => {
    await httpRoomServices.getRoom(id).then((response) => {
      setIsLoading(false);
      setDetails(response.data);
    });
  };
  React.useEffect(() => {
    fetchData();
  }, []);

  // add place to the database
  const handleSubmit = async (e) => {
    e.preventDefault();
    setFormSubmitting(true);
    httpRoomServices
      .updateRoom(formData, details, amenities, breakFast, lunch, supper, id)

      .then((response) => {
        // console.log(response.data)
        toast.success("Room Updated successfully");
        setFormSubmitting(false);
      })
      .catch((error) => {
        setFormSubmitting(false);
        toast.error(error.message);
      });
  };

  // const logState = (state) => {
  //   httpRoomServices
  //     .updateRoomStatus(state.roomReserved, details.id)
  //     .then((response) => {
  //       console.log(response.data);
  //       toast.success("Room Status Updated successfully");
  //     })
  //     .catch((error) => {
  //       toast.error("Room Status Update Failed");
  //     });
  // };

  return (
    <div className="container mt-5">
      {isLoading ? (
        <div style={{ marginTop: "20%" }} className="text-center">
          <i className="fa fa-spinner fa-spin fa-5x text-danger"></i>
        </div>
      ) : (
        <>
          <h1 className="mt-5 text-center text-capitalize">
            Edit {details?.room_name}{" "}
          </h1>
          <Form
            onKeyDown={onKeyDown}
            noValidate
            validated={validated}
            onSubmit={handleSubmit}>
            {/* <center>
              <Toggle
                toggled={details.roomReserved}
                label={`${
                  details.roomReserved ? "Reserved" : "Current Room Status"
                }`}
                onClick={logState}
              />
            </center> */}
            <Row>
              <Form.Group
                as={Col}
                md="4"
                className="mb-3"
                controlId="validationCustom03">
                <Form.Label>Guests</Form.Label>
                <Form.Control
                  disabled={formSubmitting}
                  type="number"
                  min={1}
                  defaultValue={details?.room_occupation}
                  name="room_occupation"
                  className="form-control text-capitalize"
                  onChange={(e) => onChange(e)}
                  placeholder="Maximum number of Guests"
                  required
                />
                <Form.Control.Feedback type="invalid">
                  Guests is required
                </Form.Control.Feedback>
              </Form.Group>

              <Form.Group
                as={Col}
                md="4"
                className="mb-3"
                controlId="validationCustom03">
                <Form.Label>Room Price</Form.Label>
                <Form.Control
                  type="number"
                  disabled={formSubmitting}
                  min={1}
                  defaultValue={details?.room_price}
                  name="room_price"
                  className="form-control text-capitalize"
                  onChange={(e) => onChange(e)}
                  placeholder="Room Price"
                  required
                />
                <Form.Control.Feedback type="invalid">
                  Room Price is required
                </Form.Control.Feedback>
              </Form.Group>

              <Form.Group
                as={Col}
                md="4"
                className="mb-3"
                controlId="validationCustom011">
                <Form.Label>Change Room Type</Form.Label>
                <Form.Select
                  disabled={formSubmitting}
                  className="form-control text-capitalize"
                  onChange={(e) => onChange(e)}
                  name="room_type"
                  id="room_type"
                  size="lg"
                  required
                  defaultValue={details?.room_type}>
                  <option value={details?.room_type}>
                    {details?.room_type}
                  </option>
                  <option value="luxury rooms">Luxury Room</option>
                  <option value="kings room">Kings Room</option>
                  <option value="twin room">Twin Room</option>
                  <option value="single room">Single Room</option>
                  <option value="double room">Double Room</option>
                  <option value="double double room">Double-Double Room</option>
                  <option value="family room">Family Room</option>
                  <option value="queen room">Queen Room</option>
                  <option value="superior room">Superior Room</option>
                  <option value="junior suite">Junior Suite</option>
                  <option value="triple room">Triple Room</option>
                  <option value="quad room">Quad Room</option>
                  <option value="parlour room">Parlour Room</option>
                  <option value="studio room">Studio Room</option>
                  <option value="cabana room">Cabana Room</option>
                  <option value="suite room">Suite Room</option>
                  <option value="duplex room">Duplex Room</option>
                  <option value="efficiency room">Efficiency Room</option>
                  <option value="hospital room">Hospital Room</option>
                  <option value="penthouse room">Henthouse Room</option>
                  <option value="lanai room">Lanai Room</option>
                  <option value="adjacent room">Adjacent Room</option>
                  <option value="adjoining room">adjoining Room</option>
                  <option value="interconntecting room">
                    Interconntecting Room
                  </option>
                </Form.Select>
                <Form.Control.Feedback type="invalid">
                  Charge Type is required
                </Form.Control.Feedback>
              </Form.Group>

              <div className="col-md-12">
                <div className="row">
                  <div className="mb-3 col-md-4">
                    <Form.Check // prettier-ignore
                      type="checkbox"
                      id={`breakfast-box`}
                      label={`Include Breakfast`}
                      name="inCludeBreakFast"
                      defaultChecked={formData.inCludeBreakFast}
                      required
                      onChange={(e) => setBreakFast(!breakFast)}
                    />
                  </div>
                  <div className="mb-3 col-md-4">
                    <Form.Check // prettier-ignore
                      type="checkbox"
                      id={`lunch-box`}
                      label={`Include Lunch`}
                      defaultChecked={formData.inCludeLunch}
                      name="includeLunch"
                      required
                      onChange={(e) => setLunch(!lunch)}
                    />
                  </div>
                  <div className="mb-3 col-md-4">
                    <Form.Check // prettier-ignore
                      type="checkbox"
                      id={`supper-box`}
                      label={`Include Supper`}
                      defaultChecked={formData.inCludeSupper}
                      name="inCludeSupper"
                      required
                      onChange={(e) => setSupper(!supper)}
                    />
                  </div>
                  {breakFast ? (
                    <Form.Group
                      as={Col}
                      md="4"
                      className="mb-3"
                      controlId="validationCustom03">
                      <Form.Label> BreakFast Price</Form.Label>
                      <Form.Control
                        disabled={formSubmitting}
                        type="number"
                        min={1}
                        defaultValue={details?.breakFastPrice}
                        name="breakFastPrice"
                        className="form-control text-capitalize"
                        onChange={(e) => onChange(e)}
                        placeholder="BreakFast Price"
                        required
                      />
                      <Form.Control.Feedback type="invalid">
                        Breakfast Price is required
                      </Form.Control.Feedback>
                    </Form.Group>
                  ) : (
                    <div></div>
                  )}
                  {lunch ? (
                    <Form.Group
                      as={Col}
                      md="4"
                      className="mb-3"
                      controlId="validationCustom03">
                      <Form.Label> Lunch Price</Form.Label>
                      <Form.Control
                        disabled={formSubmitting}
                        type="number"
                        min={1}
                        defaultValue={details?.lunchPrice}
                        name="lunchPrice"
                        className="form-control text-capitalize"
                        onChange={(e) => onChange(e)}
                        placeholder="Lunch Price"
                        required
                      />
                      <Form.Control.Feedback type="invalid">
                        Lunch Price is required
                      </Form.Control.Feedback>
                    </Form.Group>
                  ) : (
                    <div></div>
                  )}
                  {supper ? (
                    <Form.Group
                      as={Col}
                      md="4"
                      className="mb-3"
                      controlId="validationCustom03">
                      <Form.Label> Supper Price</Form.Label>
                      <Form.Control
                        disabled={formSubmitting}
                        type="number"
                        min={1}
                        name="supperPrice"
                        defaultValue={details?.supperPrice}
                        className="form-control text-capitalize"
                        onChange={(e) => onChange(e)}
                        placeholder="Supper Price"
                        required
                      />
                      <Form.Control.Feedback type="invalid">
                        Supper Price is required
                      </Form.Control.Feedback>
                    </Form.Group>
                  ) : (
                    <div></div>
                  )}
                </div>
              </div>

              <Form.Group as={Col} md="6" className="mb-3">
                <Form.Label>Room Description</Form.Label>
                <FloatingLabel
                  controlId="floatingTextarea2"
                  label="Room Description">
                  <Form.Control
                    as="textarea"
                    name="room_description"
                    className="form-control"
                    onChange={(e) => onChange(e)}
                    placeholder="Room Description"
                    required
                    defaultValue={details?.room_description}
                    disabled={formSubmitting}
                    rows={8}
                  />
                  <Form.Control.Feedback type="invalid">
                    Room Description is required
                  </Form.Control.Feedback>
                </FloatingLabel>
              </Form.Group>
              <Form.Group
                as={Col}
                md="6"
                className="mb-3"
                controlId="validationCustom03">
                <Form.Label>Amenities</Form.Label>
                <div className="input-tag">
                  <ul className="input-tag__tags">
                    {details?.amenities.map((tag, i) => (
                      <li key={i}>
                        {tag}
                        <button
                          type="button"
                          onClick={() => {
                            removeAminitiesTag(i);
                          }}>
                          X
                        </button>
                      </li>
                    ))}
                    {amenities.map((tag, i) => (
                      <li key={i}>
                        {tag}
                        <button
                          type="button"
                          onClick={() => {
                            removeAminitiesTag(i);
                          }}>
                          X
                        </button>
                      </li>
                    ))}
                    <li className="input-tag__tags__input">
                      <Form.Control
                        disabled={formSubmitting}
                        type="text"
                        onKeyDown={inputAminityKeyDown}
                        onChange={(e) => handleAminity(e)}
                        name="amenities"
                        className="form-control text-capitalize"
                        value={facility}
                        placeholder="Amenities, E.g TV, Wi-fi, Room Service etc"
                        ref={(c) => {
                          aminitytagInput = c;
                        }}
                      />
                      <Form.Control.Feedback type="invalid">
                        Amenities is required
                      </Form.Control.Feedback>
                    </li>
                  </ul>
                </div>
              </Form.Group>
            </Row>
            <div className="form-group text-center mb-4">
              <Button type="submit" className="btn btn-success">
                {formSubmitting ? (
                  <span>
                    <i
                      className="fa fa-refresh fa-spin"
                      style={{ marginRight: "5px" }}
                    />{" "}
                    Updating...
                  </span>
                ) : (
                  "Update Room Details"
                )}
              </Button>
            </div>
          </Form>
          <EditAddRoomNumber
            rooms={details?.room}
            room={details?.id}
            branch={details?.hotel}
            fetchData={fetchData}
          />
          <EditAddRoomImages
            roomId={details?.id}
            images={details?.room_images}
            fetchData={fetchData}
          />
        </>
      )}
    </div>
  );
}

export default EditBranchRooms;
