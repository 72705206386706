import React, { useState, useEffect, useRef } from "react";
import axios from "axios";
import moment from "moment";
import bg1 from "../../images/blog.jpg";
import user from "../../images/user.png";
import { capitalized } from "../../actions/utils";
import PostBlogComment from "./post_comment";
import RelatedBlogs from "./related_blogs";
import { Reveal } from "../../reveal";
import { Spinner } from "react-bootstrap";

function BlogDetails() {
  const queryParams = new URLSearchParams(window.location.search);
  const blog_id = queryParams.get("blog_id");
  const slug = queryParams.get("slug");
  const [number, setNumber] = useState(1); // No of pages
  const [blogPerPage] = useState(8);
  const [q, setQ] = useState("");
  const [filterParam, setFilterParam] = useState(["all"]);
  const [searchParam] = useState(["blog_title", "blog_author"]);
  const [blog, setBlog] = useState();
  const [related, setRelated] = useState([]);
  const [isLoading, setisLoading] = useState(true);
  const scrollRef = useRef(null);
  const scrowDown = () => scrollRef.current.scrollIntoView();

  // get blog data
  const fetchData = async () => {
    // get blog
    await axios
      .get(
        `${process.env.REACT_APP_API_URL}/blog/v1/intercity_travellers/blog/${blog_id}/${slug}/rmf_travellers`
      )
      .then((res) => {
        setBlog(res.data);
        setisLoading(false);
      });
    //    get related
    await axios
      .get(
        `${process.env.REACT_APP_API_URL}/blog/v1/intercity_travellers/blogs/rmf_travellers`
      )
      .then((res) => {
        setRelated(res.data);
        // setisLoading(false);
      });
  };
  useEffect(() => {
    fetchData();
  }, []);
  // pagination

  if (isLoading) {
    return (
      <div style={{ marginTop: "20%" }} className="text-center">
        <Spinner />
      </div>
    );
  }
  const lastBlog = number * blogPerPage;
  const firstBlog = lastBlog - blogPerPage;
  const currentBlogs = related.slice(firstBlog, lastBlog);
  const pageNumber = [];

  for (let i = 1; i <= Math.ceil(related.length / blogPerPage); i++) {
    pageNumber.push(i);
  }

  const ChangePage = (pageNumber) => {
    setNumber(pageNumber);
  };
  const data = Object.values(currentBlogs);

  function search(list) {
    return list.filter((item) => {
      if (item.blog_title == filterParam) {
        return searchParam.some((newItem) => {
          return (
            item[newItem].toString().toLowerCase().indexOf(q.toLowerCase()) > -1
          );
        });
      } else if (item.category == filterParam) {
        return searchParam.some((newItem) => {
          return (
            item[newItem].toString().toLowerCase().indexOf(q.toLowerCase()) > -1
          );
        });
      } else if (filterParam == "all") {
        return searchParam.some((newItem) => {
          return (
            item[newItem].toString().toLowerCase().indexOf(q.toLowerCase()) > -1
          );
        });
      }
    });
  }

  return (
    <>
      <div className="demo-wrap">
        <img className="demo-bg" src={bg1} alt="" />
        <div className="demo-content">
          <div className="container">
            <div className="row no-gutters slider-text js-fullheight align-items-end justify-content-center">
              <div className="col-md-9 ftco-animate pb-5 text-center">
                <p className="breadcrumbs">
                  <span className="mr-2">
                    <a href="/">
                      Home <i className="fa fa-chevron-right"></i>
                    </a>
                  </span>{" "}
                  <span className="mr-2">
                    <a href="/zm/blogs">
                      Blog <i className="fa fa-chevron-right"></i>
                    </a>
                  </span>{" "}
                  <span>
                    Details <i className="fa fa-chevron-right"></i>
                  </span>
                </p>
                <h1 className="mb-0 bread">Blog Details</h1>
              </div>
            </div>
          </div>
        </div>
      </div>

      <section ref={scrollRef} className="ftco-section ftco-no-pt ftco-no-pb">
        <div className="container">
          <div className="row">
            <div className="col-lg-8 ftco-animate">
              <p>
                <img
                  src={`${blog.image}`}
                  style={{ width: "100%", height: "50%" }}
                  alt=""
                  className="img-fluid"
                />
              </p>
              <div className="about-author py-2 px-2 d-flex shadow border-0 rounded-3">
                <div className="bio mr-2">
                  <img
                    src={user}
                    width={50}
                    height={50}
                    alt="Image placeholder"
                    className="img-fluid mb-2"
                  />
                </div>
                <div className="desc">
                  <h3 className="text-capitalize">{blog.blog_author}</h3>
                  <p>
                    <i className="fa fa-calendar"></i>{" "}
                    {moment(blog.created_aat).format("MMMM Do YYYY")}
                  </p>
                </div>
              </div>
              <h2 className="mb-3">{blog.blog_title}</h2>
              <p>{blog.blog_body}</p>

              <div className="tag-widget post-tag-container mb-5 mt-5">
                <div className="tagcloud">
                  <a className="tag-cloud-link">{blog.category}</a>
                </div>
              </div>
              <div className="pt-5 mt-5">
                <h3
                  className="mb-5"
                  style={{ fontSize: "20px", fontWeight: "bold" }}>
                  {blog.comments?.length === 0 ? (
                    <div></div>
                  ) : (
                    `${blog.comments?.length} Comments`
                  )}
                </h3>
                <Reveal>
                  <ul className="comment-list">
                    {blog.comments?.length < 1 ? (
                      <div className="alert alert-info text-center">
                        <i className="fa fa-comments fa-5x"></i>
                        <p>No Comments</p>
                      </div>
                    ) : (
                      blog.comments.map((item, index) => (
                        <li key={index} className="comment">
                          <div className="vcard bio">
                            <img src={user} alt="Image placeholder" />
                          </div>
                          <div className="comment-body">
                            <h3 className="text-capitalize">
                              {item.user.first_name} {item.user.last_name}
                            </h3>
                            <small className="text-gray">
                              {moment(item.created_at).format("MMMM Do YYYY")}
                            </small>

                            <blockquote>
                              {capitalized(item.blog_comment)}
                            </blockquote>
                          </div>
                        </li>
                      ))
                    )}
                  </ul>
                </Reveal>
                {/* <!-- END comment-list --> */}

                <div className="comment-form-wrap pt-5">
                  <h3
                    className="mb-5"
                    style={{ fontSize: "20px", fontWeight: "bold" }}>
                    Leave a comment
                  </h3>
                  <PostBlogComment fetchBlogs={fetchData} id={blog_id} />
                </div>
              </div>
            </div>
            <div className="col-lg-4 ftco-animate shadow border-0 rounded-3 py-md-5">
              <Reveal>
                <div className="sidebar-box pt-md-5">
                  <form action="#" className="search-form">
                    <div className="form-group">
                      <span className="icon fa fa-search"></span>
                      <input
                        type="search"
                        className="form-control"
                        placeholder="Search..."
                        value={q}
                        onChange={(e) => setQ(e.target.value)}
                      />
                    </div>
                  </form>
                </div>
              </Reveal>

              <div className="sidebar-box ftco-animate">
                <h3>Recent Blogs</h3>

                <RelatedBlogs
                  related={search(data).filter((item) => item.id != blog.id)}
                />
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}

export default BlogDetails;
