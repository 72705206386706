import React from "react";
import { IBranch } from "../../../redux/types/hotel_branch.type";
import { useGetBranchesQuery } from "../../../redux/slices/branchAPISlice";
import LoadingSpinner from "../../../components/widgets/spinner";
import { urlToken } from "../../../helper";

interface IBranchD {
  uid: number;
}

const BranchDisplay = ({ uid }: IBranchD) => {
  const {
    data: branches,
    isLoading,
    error,
  }: IBranch | any = useGetBranchesQuery(uid);

  if (isLoading) {
    return (
      <center>
        <LoadingSpinner />
      </center>
    );
  }
  if (error) {
    return (
      <div className="text-center alert alert-danger" role="alert">
        <h4>{error.status}</h4>
      </div>
    );
  }
  return (
    <>
      <div className="card mb-0">
        <div className="card-body">
          <h4 className="card-title">Branches</h4>
          <div className="table-responsive dataview">
            <table className="table datatable">
              <thead>
                <tr>
                  <th>#</th>
                  <th>Branch Location</th>
                  <th>Branch E-mail</th>
                  <th>Branch Phone Number</th>
                  <th>Action</th>
                </tr>
              </thead>
              <tbody>
                {branches.map((item: IBranch, index: number) => {
                  return (
                    <tr>
                      <td>{index + 1}</td>
                      <td className="text-capitalize">
                        {item.province.province_name}{" "}
                        {item.district.district_name}
                      </td>
                      <td className="productimgname">{item.hotel_email}</td>
                      <td>{item.hotel_phone}</td>
                      <td>
                        <a
                          className="btn btn-sm text-white btn-info"
                          href={`/zm/hotel_dashboard/hotel_rooms/${urlToken}/${item.id}/${uid}`}>
                          Branch Details <i className="fa fa-arrow-right"></i>
                        </a>
                      </td>
                    </tr>
                  );
                })}
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </>
  );
};

export default BranchDisplay;
