import React from "react";
import dashboard from "../../../images/logo.png";
import product from "../../../assets/img/icons/product.svg";
import { Link } from "react-router-dom";
import { useAppSelector } from "../../../hooks/redux-hooks";

const SideBar: React.FC = () => {
  const user = useAppSelector((state) => state.auth.user);
  function randomString(length: number, chars: string) {
    var result = "";
    for (var i = length; i > 0; --i)
      result += chars[Math.floor(Math.random() * chars.length)];
    return result;
  }
  var token = randomString(100, `${localStorage.getItem("access")}`);
  return (
    <>
      <div className="sidebar" id="sidebar">
        <div className="sidebar-inner slimscroll">
          <div id="sidebar-menu" className="sidebar-menu">
            <ul>
              <li className="active">
                <Link to={`/zm/hotel_dashboard/?uid=${user?.id}`}>
                  <img src={dashboard} alt="img" />
                  <span>Dashboard</span>
                </Link>
              </li>
              <li className="submenu">
                <a href="javascript:void(0);">
                  <img src={product} alt="img" />
                  <span>Branches</span>
                  <span className="menu-arrow"></span>
                </a>
                <ul>
                  <li>
                    <Link to={`/zm/hotel_dashboard/view_hotels/${token}/${user?.id}/?hotel=${user?.company_name}`}>View Branches</Link>
                  </li>
                </ul>
              </li>
              <li className="submenu">
                <a href="javascript:void(0);">
                  <i className="fa fa-hotel"></i>
                  <span>Rooms</span>
                  <span className="menu-arrow"></span>
                </a>
                <ul>
                  <li>
                    <Link to={`/zm/hotel_dashboard/view_hotel_rooms/${token}/${user?.id}`}>View Rooms</Link>
                  </li>
                </ul>
              </li>
              <li className="submenu">
                <a href="javascript:void(0);">
                  <i className="fa fa-book-open"></i>
                  <span>Reservations</span>
                  <span className="menu-arrow"></span>
                </a>
                <ul>
                  <li>
                    <Link to={`/zm/hotel_dashboard/hotel_reservations/${token}/${user?.id}`}>View Reservations</Link>
                  </li>
                </ul>
              </li>
              <li className="submenu">
                <a href="javascript:void(0);">
                  <i className="fa fa-users"></i>
                  <span>Customers</span>
                  <span className="menu-arrow"></span>
                </a>
                <ul>
                  <li>
                    <Link to={`/zm/hotel_dashboard/customers/${token}/`}>View Customers</Link>
                  </li>
                </ul>
              </li>
              <li className="submenu">
                <a href="javascript:void(0);">
                  <i className="fa fa-book"></i>
                  <span>Terms & Privacy</span>
                  <span className="menu-arrow"></span>
                </a>
                <ul>
                  <li>
                    <Link to={`/zm/hotel_dashboard/terms_of_service/${token}/${user?.id}`}>View Terms of Service</Link>
                  </li>
                  <li>
                    <Link to={`/zm/hotel_dashboard/privacy_policy/${token}/${user?.id}`}>View Privacy Policy</Link>
                  </li>
                </ul>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </>
  );
};

export default SideBar;
