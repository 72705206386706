import React, { useState, useEffect } from "react";
import axios from "axios";
import moment from "moment";
import { useParams } from "react-router-dom";
import LoadingSpinner from "../../components/widgets/spinner";
import { useGetReservationsQuery } from "../../redux/slices/room_reservation_slice";
import { IRoomReservation } from "../../redux/types/room_reservation.type";

const HotelReservations = () => {
  const [number, setNumber] = useState(1); // No of pages
  const [roomPerPage] = useState(20);
  const [q, setQ] = useState("");
  const [searchParam] = useState([
    "first_name",
    "last_name",
    "email",
    "status",
  ]);
  const [filterParam, setFilterParam] = useState("all");

  const { uid } = useParams();

  const {
    data: reservations,
    isLoading,
    error,
  }: IRoomReservation | any = useGetReservationsQuery({
    hotel_owner: uid,
    status: "confirmed",
  });

  if (isLoading) {
    return (
      <center>
        <LoadingSpinner />
      </center>
    );
  }
  if (error) {
    return (
      <div className="text-center alert alert-danger" role="alert">
        <h4>{error.status}</h4>
      </div>
    );
  }

  let ticketAmount = reservations.map(
    (item: IRoomReservation) => item.room_price
  );

  const total_amount = ticketAmount.reduce(function (acc: number, val: number) {
    return acc + val;
  }, 0);

  function findDayDifference(date1: any, date2: any) {
    // always round down
    return Math.floor(Math.abs(date2 - date1) / (1000 * 60 * 60 * 24));
  }

  // pagination
  const lastRoom = number * roomPerPage;
  const firstRoom = lastRoom - roomPerPage;
  const currentRooms = reservations.slice(firstRoom, lastRoom);
  const pageNumber = [];

  for (let i = 1; i <= Math.ceil(reservations.length / roomPerPage); i++) {
    pageNumber.push(i);
  }

  const ChangePage = (pageNumber: number) => {
    setNumber(pageNumber);
  };
  const data = Object.values(currentRooms);

  function search(rooms: any[]) {
    return rooms.filter((item) => {
      if (item.first_name === filterParam) {
        return searchParam.some((newItem) => {
          return (
            item[newItem].toString().toLowerCase().indexOf(q.toLowerCase()) > -1
          );
        });
      } else if (item.date_checkin.toString() === filterParam) {
        return searchParam.some((newItem) => {
          return (
            item[newItem].toString().toLowerCase().indexOf(q.toLowerCase()) > -1
          );
        });
      } else if (filterParam === "All") {
        return searchParam.some((newItem) => {
          return (
            item[newItem].toString().toLowerCase().indexOf(q.toLowerCase()) > -1
          );
        });
      }
    });
  }

  return (
    <>
      <div className="container-fluid mt-5">
        <div className="row">
          <div className="col px-4 py-5">
            <div className="container">
              {/* <!-- summary analytics --> */}
              <div className="row my-3">
                <div className="col-md-6 mb-3">
                  <div className="card shadow border-0 rounded-3">
                    <div className="card-body">
                      <div className="row">
                        <div className="col mt-0">
                          <h4 className="card-title text-muted">
                            Confirmed Reservations
                          </h4>
                        </div>

                        <div className="col-auto">
                          <div className="stat text-primary">
                            <i
                              className="bi bi-ticket-detailed-fill"
                              style={{
                                fontSize: "2rem",
                                color: "rgb(192, 52, 52)",
                              }}></i>
                          </div>
                        </div>
                      </div>
                      <h5 className="mt-1 mb-3">{reservations.length}</h5>
                    </div>
                  </div>
                </div>

                <div className="col-md-6 mb-3">
                  <div className="card shadow border-0 rounded-3">
                    <div className="card-body">
                      <div className="row">
                        <div className="col mt-0">
                          <h4 className="card-title text-muted">Revenue</h4>
                        </div>

                        <div className="col-auto">
                          <div className="stat text-primary">
                            <i
                              className="bi bi-cash-coin"
                              style={{
                                fontSize: "2rem",
                                color: "rgb(7, 145, 7)",
                              }}></i>
                          </div>
                        </div>
                      </div>
                      <h5 className="mt-1 mb-3">
                        ZMW {total_amount.toLocaleString()}
                      </h5>
                    </div>
                  </div>
                </div>
              </div>
              {/* <!-- end of summary analytics --> */}
            </div>
            <div className="row">
              <div className="col-md-3 mt-4">
                <h3 className="text-capitalize text-black">
                  Room Reservations
                </h3>
              </div>
              <div className="col-md-3">
                <label htmlFor="search-form">Search</label>
                <div className="form-group">
                  <input
                    type="search"
                    name="search-form"
                    id="search-form"
                    className="form-control"
                    placeholder="Search for..."
                    value={q}
                    onChange={(e) => setQ(e.target.value)}
                  />
                </div>
              </div>
              <div className="col-md-3">
                <label htmlFor="search-form">Search By Date</label>
                <div className="form-group">
                  <select
                    className="form-control"
                    name="search-form"
                    id="search-form"
                    onChange={(e) => setFilterParam(e.target.value)}>
                    <option value="all" selected>
                      All
                    </option>
                    {reservations.map(
                      (room: IRoomReservation, index: number) => (
                        <option
                          key={index}
                          value={room.date_checkin.toString()}>
                          {" "}
                          {moment(room.date_checkin).format("MMMM Do, YYYY")}
                        </option>
                      )
                    )}
                  </select>
                </div>
              </div>
              <div className="col-md-3">
                <label htmlFor="search-form">Search By Status</label>
                <div className="form-group">
                  <select
                    className="form-control"
                    name="search-form"
                    id="search-form"
                    onChange={(e) => setFilterParam(e.target.value)}>
                    <option value="all" selected>
                      All
                    </option>
                    <option value="confirmed">Confirmed</option>
                    <option value="rejected">Rejected</option>
                    <option value="cancelled">Cancelled</option>
                  </select>
                </div>
              </div>
            </div>

            <div className="table-responsive my-4">
              {reservations.length === 0 ? (
                <div className="alert alert-danger text-center">
                  There are no Reservations
                </div>
              ) : (
                <table className="table table-hover">
                  <thead>
                    <tr>
                      <th>#</th>
                      <th>Name</th>
                      <th>E-mail</th>
                      <th>Phone Number</th>
                      <th>Paid</th>
                      <th>Room</th>
                      <th>Date Checkin / Checkout</th>
                      <th>Status</th>
                    </tr>
                  </thead>

                  <tbody>
                    {search(data).map(
                      (room: IRoomReservation, index: number) => (
                        <tr key={index}>
                          <td>{index + 1}</td>
                          <td className="text-capitalize">
                            {room.first_name} {room.last_name}
                          </td>

                          <td>{room.email}</td>
                          <td>{room.phone_number}</td>
                          <td>ZMW {room.room_price}</td>
                          <td className="text-capitalize">
                            {room.room_type} ({room.room_number})
                          </td>
                          <td>
                            {moment(room.date_checkin).format("MMM do YYYY")} -{" "}
                            {moment(room.date_checkout).format("MMM do YYYY")},{" "}
                            <small className="text-warning">
                              {findDayDifference(
                                new Date(
                                  moment().format(room.date_checkin.toString())
                                ),
                                new Date(
                                  moment().format(room.date_checkout.toString())
                                )
                              )}
                              Days
                            </small>
                          </td>
                          <td>
                            <div className="text-capitalize text-success">
                              <b>{room.status}</b>
                            </div>
                          </td>
                        </tr>
                      )
                    )}
                  </tbody>
                </table>
              )}
              {data.length < 50 ? (
                <div></div>
              ) : (
                <div className="container pull-right">
                  <div className="pagination">
                    <div className="page-item">
                      <button
                        disabled={number === 1}
                        className="text-center btn-primary py-0"
                        onClick={() => setNumber(number - 1)}>
                        <i className="fa fa-chevron-left"></i>
                      </button>
                    </div>

                    {pageNumber.map((Elem, index) => {
                      return (
                        <div key={index} className="page-item">
                          <button
                            className={`text-center text-white ${
                              number === Elem ? "active" : ""
                            } btn-outline-dark`}
                            onClick={() => ChangePage(Elem)}>
                            {Elem}
                          </button>
                        </div>
                      );
                    })}
                    <div className="page-item">
                      <button
                        className="text-center btn-primary"
                        onClick={() => setNumber(number + 1)}>
                        <i className="fa fa-chevron-right"></i>
                      </button>
                    </div>
                  </div>
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default HotelReservations;
