import React from "react";

import { Carousel, Modal } from "react-bootstrap";
import { capitalized } from "../../actions/utils";

function BranchDetails({ branch, show, handleClose, provinces, districts }) {
  return (
    <>
      {/* view place details modal */}
      <Modal
        backdrop="static"
        keyboard={false}
        centered
        size="xl"
        scrollable={true}
        show={show}
        onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title>Branch Details</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="row">
            <div className="col-md-6">
              <Carousel fade indicators={false} controls={true}>
                {branch.branch_images.map((item, index) => {
                  return (
                    <Carousel.Item key={index}>
                      <img
                        className="img-responsive"
                        src={item.image}
                        style={{
                          borderRadius: "10px",
                          width: "100%",
                          height: "400px",
                        }}
                        alt={branch.hotel_name}
                      />
                    </Carousel.Item>
                  );
                })}
              </Carousel>
            </div>
            <div className="col-md-6">
              <h2 className="text-capitalize text-center">
                {branch.hotel_name}
              </h2>
              <h5 className="mt-3">
                <i className="fa fa-map text-success"></i> General Facilities:{" "}
              </h5>
              <small className="text-capitalize mb-3 mt-3">
                <div className="row">
                  {branch.amenities.map((item, index) => (
                    <div key={index} className="col-md-4">
                      <li>{item}</li>
                    </div>
                  ))}
                </div>
              </small>
              <h6 className="text-capitalize">
                <i className="fa fa-map-marker text-success"></i> Location:{" "}
                <b>{branch.province.province_name}</b>,{" "}
                <b>{branch.district.district_name}</b>
              </h6>
              <h6 className="text-capitalize">
                <i className="fa fa-map text-success"></i> Address:{" "}
                <b>{branch.address}</b>
              </h6>
              <h6 className="text-capitalize">
                <i className="fa fa-mobile text-success"></i> Contact:{" "}
                <b>{branch.hotel_phone}</b>
              </h6>
              <h6>
                <i className="fa fa-envelope text-success"></i> E-mail:{" "}
                <b>{branch.hotel_email}</b>
              </h6>
              <h5 className="mt-3">
                <i className="fa fa-book text-success"></i> Summary:{" "}
              </h5>
              <p>{capitalized(branch.hote_description)}</p>
            </div>
          </div>
        </Modal.Body>
      </Modal>
    </>
  );
}

export default BranchDetails;
