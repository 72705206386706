import React, { useEffect, useState } from "react";
import axios from "axios";
import moment from "moment";
import AddHotelLodge from "./add_hotel_lodge";
import LoadingSpinner from "../../components/widgets/spinner";

function Hotels() {
  const [company, setCompany] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  useEffect(() => {
    const config = {
      headers: {
        "Content-Type": "application/json",
        Authorization: `JWT ${localStorage.getItem("access")}`,
        Accept: "application/json",
      },
    };
    const fetchData = async () => {
      await axios
        .get(
          `${process.env.REACT_APP_API_URL}/userprofile/v1/intercity_travellers/get_hotels/rmf_travellers`,
          config
        )
        .then((res) => {
          setCompany(res.data);
          setIsLoading(false);
        });
    };
    fetchData();
  }, []);
  return (
    <>
      <div className="wrapper align-items-stretch">
        <div id="content" className="p-2 p-md-3 pt-3 mt-5 container">
          <h1 className="text-center">Hotels / Lodges</h1>
          {/* <FormikValidation /> */}
          <div className="mt-5 mb-3 pull-right">
            <AddHotelLodge
              show={show}
              handleShow={handleShow}
              handleClose={handleClose}
            />
          </div>

          {isLoading ? (
            <LoadingSpinner />
          ) : (
            <div className="table-responsive">
              <table class="table table-hover text-dark">
                <thead>
                  <tr>
                    <th>#</th>
                    <th>Company Name</th>
                    <th>SamPay ID</th>
                    <th>Shares</th>
                    <th>Date Created</th>
                    <th>Action</th>
                  </tr>
                </thead>
                <tbody>
                  {company.map((res, index) => (
                    <tr key={index}>
                      <td>{index + 1}</td>
                      <td className="text-capitalize">{res.company_name}</td>
                      <td>{res.mechant_id}</td>
                      <td>{100 - res.percentage_share}</td>
                      <td>
                        {moment(res.created_at).format("MMMM Do YYYY H:m a")}
                      </td>
                      <td>
                        <a
                          href={`/zm/intercity_travellers_admin/edit_account/${res.id}`}>
                          <i className="fa fa-pencil"></i> Edit
                        </a>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          )}
        </div>
      </div>
    </>
  );
}

export default Hotels;
