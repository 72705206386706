import http from "./http.common";

class DashbordServices {
  // CREATE BRANCH
  async createBranches(data, generalFacilities, image1, image2, uid, hotel) {
    let form_data = new FormData();
    form_data.append("user", uid);
    form_data.append("image1", image1);
    form_data.append("image2", image2);
    form_data.append("hotel_name", hotel);
    form_data.append("hotel_phone", data.hotel_phone);
    form_data.append("hotel_email", data.hotel_email);
    form_data.append("hote_description", data.hote_description);
    form_data.append("province", data.province);
    form_data.append("district", data.district);
    form_data.append("latitude", -17.8466704);
    form_data.append("longitude", 25.850099);
    form_data.append("checkinTime", data.checkinTime);
    form_data.append("checkoutTime", data.checkoutTime);
    form_data.append("branchType", data.branchType);
    form_data.append("address", data.address);
    form_data.append("cancellationPolicy", data.cancellationPolicy);
    form_data.append("amenities", JSON.stringify([...generalFacilities]));
    console.log(`FORM DATA: ${[...form_data]}`);
    return await http.post(
      `/hotels/v1/intercity_travellers/create_hotel/rmf_travellers`,
      form_data
    );
  }

  // SUBMIT BRANCH IMAGES
  async submitBrancheImage(body) {
    return await http.post(
      `/hotels/v1/intercity_travellers/branch_images/rmf_travellers`,
      body
    );
  }

  // get branch by id
  async getDistrictsByProvince(provinceID) {
    return await http.get(
      `/api/v1/intercity_travellers/province-districts/${provinceID}/rmf_travellers`
    );
  }

  // UPDATE BRANCH
  async updateBranch(data, generalFacilities, uid, id) {
    let form_data = new FormData();
    form_data.append("user", uid);
    // form_data.append("image1", image1);
    // form_data.append("image2", image2);
    form_data.append("hotel_phone", data.hotel_phone);
    form_data.append("hotel_email", data.hotel_email);
    form_data.append("hote_description", data.hote_description);
    form_data.append("province", data.province);
    form_data.append("district", data.district);
    form_data.append("latitude", -17.8466704);
    form_data.append("longitude", 25.850099);
    form_data.append("checkinTime", data.checkinTime);
    form_data.append("checkoutTime", data.checkoutTime);
    form_data.append("branchType", data.branchType);
    form_data.append("address", data.address);
    form_data.append("cancellationPolicy", data.cancellationPolicy);
    form_data.append("amenities", JSON.stringify([...generalFacilities]));
    console.log(`FORM DATA: ${[...form_data]}`);
    return await http.patch(
      `/hotels/v1/intercity_travellers/edit_hotel/${id}/rmf_travellers`,
      form_data
    );
  }

  async fetchSingleBranch(branchId) {
    return await http.get(
      `/hotels/v1/intercity_travellers/edit_hotel/${branchId}/rmf_travellers`
    );
  }

  async fetchBranchImages(branchId) {
    return await http.get(
      `hotels/v1/intercity_travellers/retrieve_branch_images/${branchId}/rmf_travellers`
    );
  }

  async getProvinces() {
    return await http.get(`/api/v1/intercity_travellers/home/rmf_travellers`);
  }

  async getDistricts() {
    return await http.get(
      `/api/v1/intercity_travellers/districts/rmf_travellers`
    );
  }

  // delete branch
  async deletePropery(id) {
    return await http.delete(`/api/v1/peza/admin/bondi/${id}/`);
  }

  async changeUserPassword(body, uid) {
    return await http.patch(`/users/v1/update_settings/${uid}`, body);
  }

  // BRANCH REPORTS
  async getReports(branch, groupBy) {
    return await http.get(
      `/hotels/v1/intercity_travellers/branch_reservations_report/rmf_travellers/?group_by=${groupBy}&hotel_id=${branch}`,
    );
  }
}

export default new DashbordServices();
