import React, { useState } from "react";
import { useForm } from "react-hook-form";
import Swal from "sweetalert2";
import axios from "axios";
import { Modal } from "react-bootstrap";
import { blogCategories } from "../../components/data/blog_categories";

function PostBlog({ fetchBlogs }) {
  const [formSubmitting, setFormSubmitting] = useState(false);
  const [image1, setImage1] = useState(null);
  const [previewImage, setPreviewImage] = useState(null);
  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm();

  const handleImage = (e) => {
    setImage1(e.target.files[0]);
    setPreviewImage(URL.createObjectURL(e.target.files[0]));
  };

  const handleBlogSubmit = async (data) => {
    // e.preventDefault();
    setFormSubmitting(true);
    const config = {
      headers: {
        "Content-Type": "multipart/form-data",
        Authorization: `JWT ${localStorage.getItem("access")}`,
      },
    };

    let form_data = new FormData();
    form_data.append("image", image1);
    form_data.append("blog_title", data.blog_title);
    form_data.append("blog_author", data.blog_author);
    form_data.append("blog_body", data.blog_body);
    form_data.append("category", data.category);

    await axios
      .post(
        `${process.env.REACT_APP_API_URL}/blog/v1/admin_blogs/`,
        form_data,
        config
      )
      .then((response) => {
        // console.log(response.data)
        Swal.fire("Successful", "Blog Created Successfully", "success");
        fetchBlogs();
        setShow(false);
        setFormSubmitting(false);
      })
      .catch((error) => {
        setFormSubmitting(false);
        Swal.fire("Error", "An Error occurred try again later!", "error");
        console.error("There was an error!", error);
      });
  };
  return (
    <>
      <div className="mb-5 text-center">
        <button onClick={handleShow} className="btn btn-info" type="button">
          Create Blog
        </button>
      </div>
      <Modal
        show={show}
        onHide={handleClose}
        backdrop="static"
        size="xl"
        scrollable
        keyboard={false}
        centered={true}
      >
        <Modal.Header closeButton>
          <Modal.Title>Create Blog</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <form onSubmit={handleSubmit(handleBlogSubmit)}>
            <div className="container">
              <div className="row">
                <div className="col-md-6 form-group">
                  <label htmlFor="blog_title">
                    Blog Title <span className="text-danger">*</span>
                  </label>
                  <input
                    type="text"
                    name="blog_title"
                    className="form-control"
                    disabled={formSubmitting}
                    {...register("blog_title", { required: true })}
                    placeholder="Blog Title *"
                  />
                  {errors.blog_title && (
                    <p className="text-danger">This field is required</p>
                  )}
                </div>
                <div className="col-md-6 form-group">
                  <label htmlFor="blog_author">
                    Blog Author <span className="text-danger">*</span>
                  </label>
                  <input
                    type="text"
                    disabled={formSubmitting}
                    name="blog_author"
                    className="form-control"
                    {...register("blog_author", { required: true })}
                    placeholder="Blog Author *"
                  />
                  {errors.blog_author && (
                    <p className="text-danger">This field is required</p>
                  )}
                </div>
                <div
                  className={`${
                    previewImage === null ? "" : "col-md-2"
                  } form-group`}
                >
                  {previewImage && (
                    <img
                      width={150}
                      height={100}
                      src={previewImage}
                      alt="intercity travellers"
                      srcset=""
                    />
                  )}
                </div>
                <div
                  className={`${
                    previewImage === null ? "col-md-6" : "col-md-4"
                  } form-group`}
                >
                  <label htmlFor="blog_image">
                    Blog Image <span className="text-danger">*</span>
                  </label>
                  <input
                    type="file"
                    name="image"
                    disabled={formSubmitting}
                    className="form-control"
                    {...register("image", {
                      required: true,
                      onChange: handleImage,
                    })}
                    placeholder="Blog Image"
                  />
                  {errors.image && (
                    <p className="text-danger">This field is required</p>
                  )}
                </div>

                <div className="col-md-6 form-group">
                  <label htmlFor="category">
                    Blog Category <span className="text-danger">*</span>
                  </label>
                  <select
                    {...register("category", { required: true })}
                    className="text-capitalize form-control"
                    name="category"
                  >
                    <option selected disabled>
                      Select Category
                    </option>
                    {blogCategories.map((item, index) => (
                      <option key={index} value={item.toLowerCase()}>
                        {item}
                      </option>
                    ))}
                  </select>
                  {errors.image && (
                    <p className="text-danger">This field is required</p>
                  )}
                </div>
              </div>

              <div className="form-group">
                <label htmlFor="blog_body">
                  Blog <span className="text-danger">*</span>
                </label>
                <textarea
                  name="blog_body"
                  placeholder="Blog *"
                  disabled={formSubmitting}
                  className="form-control"
                  cols="12"
                  rows="8"
                  {...register("blog_body", { required: true })}
                ></textarea>
                {errors.blog_body && (
                  <p className="text-danger">This field is required</p>
                )}
              </div>
            </div>
            <div className="modal-footer">
              <div className="col-md-3">
                <button
                  className="btn btn-primary form-control"
                  disabled={formSubmitting}
                >
                  {formSubmitting && (
                    <span className="wc-editable">
                      <i className="fa fa-refresh fa-spin" /> Publishing ....
                    </span>
                  )}
                  {!formSubmitting && (
                    <span className="wc-editable">Publish</span>
                  )}
                </button>
              </div>
            </div>
          </form>
        </Modal.Body>
      </Modal>
    </>
  );
}

export default PostBlog;
