import React from "react";
import ReactHtmlParser from "html-react-parser";
import { Carousel } from "react-bootstrap";
import { urlToken } from "../../../helper";
import { IRoom } from "../../../redux/types/hotel_room.type";

interface IRoomCard{
  room: IRoom;
  uid: any;
  handleShowRoomDetailsModal: (room: IRoom) => void;
}

function RoomCard({ room, uid, handleShowRoomDetailsModal }: IRoomCard) {
  return (
    <>
      <div className="row">
        <a
          style={{ cursor: "pointer" }}
          onClick={() => handleShowRoomDetailsModal(room)}>
          <Carousel fade indicators={false} controls={false}>
            {room.room_images?.map((item, index) => {
              return (
                <Carousel.Item key={index}>
                  <img
                    className="img-responsive"
                    src={item.image}
                    style={{
                      height: "200px",
                      width: "100%",
                      borderRadius: "5px",
                    }}
                    alt={room.room_name}
                  />
                </Carousel.Item>
              );
            })}
          </Carousel>
        </a>

        <div className="text-capitalize">
          <h3>{room.room_name}</h3>
        </div>
        <div className="">
          {ReactHtmlParser(room.room_description.substring(0, 150))}

          <a
            style={{ cursor: "pointer", color: "orangered" }}
            onClick={() => handleShowRoomDetailsModal(room)}>
            .... <i className="fa fa-arrow-circle-right"></i> More
          </a>
        </div>
        <div>
          <a
            className="btn btn-sm btn-success text-white"
            href={`/zm/hotel_dashboard/edit_room/${urlToken}/${room.id}/${uid}`}>
            <i className="fa fa-edit"></i> Edit
          </a>
        </div>
      </div>
    </>
  );
}

export default RoomCard;
