import React, { useEffect } from "react";
import HeroSection from "../widgets/hero_section";
import Activities from "./widgets/activities";
import PlacesCard from "./widgets/places_card";
import DestinationsCard from "../widgets/destinations_card";
import { styles, urlToken } from "../../helper";
import { capitalized } from "../../actions/utils";
import ReactHtmlParser from "html-react-parser";
import { Reveal } from "../../reveal";
import { motion } from "framer-motion";
import Places from "../../accounts/customer/places";
import PromptApp from "../about/prompt_app";
import BlogSkeleton from "../loading/blog_loading";
import ProvinceSkeleton from "../loading/province_loading";
import httpApiService from "../services/http.api.service";
import BlogCard from "../blog/widgets/blog_card";
import { Helmet } from "react-helmet";
import HomePageCars from "./home_page_cars";
import CarHireCompanies from "./widgets/car_hire_company";

const HomePage = () => {
  const [isHolidayLoading, setisHolidayLoading] = React.useState(true);
  const [blogs, setBlogs] = React.useState([]);
  const [carCompanies, carRentals] = React.useState([]);
  const [province, setprovince] = React.useState<any[]>([]);
  const [holidays, setHolidays] = React.useState([]);
  const [isLoading, setisLoading] = React.useState(true);
  const [blogLoading, setBlogLoading] = React.useState(true);
  const [show, setShow] = React.useState(false);
  const handleClose = () => {
    setShow(false);
  };
  const showpanel = () => {
    setShow(true);

    localStorage.setItem("promptUser", new Date().getDate().toString());
  };
  const fetchData = async () => {
    try {
      httpApiService.getCarCompanies().then((response) => {
        carRentals(response.data);
      });
    } catch (error) {}
    // fetch Holiday Packages
    try {
      httpApiService.getHolidayPackages().then((response) => {
        setisHolidayLoading(false);
        setHolidays(response.data);
      });
    } catch (error) {
      setisHolidayLoading(false);
    }
    try {
      httpApiService.fetchProvinces().then((res) => {
        setprovince(res.data);
        setisLoading(false);
      });
    } catch (error) {}

    try {
      httpApiService.fetchBlogs().then((res) => {
        setBlogs(res.data);
        setBlogLoading(false);
      });
    } catch (error) {}
  };

  useEffect(() => {
    
    fetchData();
    if (
      localStorage.getItem("promptUser") !== new Date().getDate().toString()
    ) {
      setTimeout(showpanel, 4000);
    }
  }, []);
  return (
    <>
      <Helmet>
        <title>Intercity Travellers | Home </title>
        <meta
          property="og:url"
          content="https://www.intercitytravellers.com"
        />
        <meta property="og:title" content="Intercity Travellers" />
        <meta
          name="viewport"
          content="width=device-width, initial-scale=1, maximum-scale=1"
        />
        <meta
          name="description"
          content="Bus Ticket reservation platform for Public and Private Buses.
      Zambia’s No. 1 Online bus ticket booking app which offers a convenient and simple process for booking bus tickets with different public and private bus operators at low prices, Intercity Bus travelers is now an Authorized partner to sell bus tickets to every person that is looking to travel to any state in Zambia and Central Africa."
        />
        <meta
          name="keywords"
          content="intercity travellers,intercity travels,intercity bus"
        />
      </Helmet>
      <HeroSection />
      {/* <FilterHotelsHolidays /> */}
      <Activities />
      <PromptApp show={show} handleClose={handleClose} />
      <Places />
      <PlacesCard />
      <DestinationsCard data={holidays} isLoading={isHolidayLoading} />
      <Reveal>
        <div className="container">
          <div className="row justify-content-center">
            <div className="col-md-12 heading-section text-center ftco-animate">
              <span className="subheading mt-3">Let's Explore</span>
              <h2>Provinces</h2>
            </div>
          </div>

          <div className="row ">
            {isLoading ? (
              <ProvinceSkeleton />
            ) : province.length === 0 ? (
              <div className="alert alert-info text-center" role="alert">
                <h1>
                  <i className="fa fa-globe"></i>
                </h1>
                <h3> No Provinces Found</h3>
              </div>
            ) : (
              province.slice(0,6).map((prov, index) => (
                <div
                  key={index}
                  data-aos="fade-up"
                  data-aos-delay={index * 250}
                  className="col-md-4 mb-3 tourism-container display-img">
                  <a
                    href={`/zm/province/?token=${urlToken}&slug=${prov.slug}&province=${prov.id}`}>
                    <img
                      className="img-fluid tour-img"
                      src={prov.thumbnail}
                      alt={prov.province_name}
                      style={{
                        width: "100%",
                        height: "200px",
                        borderRadius: "10px",
                      }}
                    />
                  </a>

                  <div>
                    <h5 className="text-capitalize mt-2">
                      <b>{prov.province_name}</b>
                    </h5>
                    <small>
                      {ReactHtmlParser(
                        capitalized(prov.about_province.substring(0, 150))
                      )}{" "}
                      <motion.a
                        href={`/zm/province/?token=${urlToken}&slug=${prov.slug}&province=${prov.id}`}
                        // style={styles}
                        className="pull-right"
                        whileHover={{ scale: 1.1 }}
                        whileTap={{ scale: 0.9, x: "-5px", y: "5px" }}>
                        Explore
                      </motion.a>
                    </small>
                  </div>
                </div>
              ))
            )}
          </div>
          <center>
            <motion.a
              href={`/zm/zambia-tourism`}
              className="btn btn-primary mt-3 mb-3"
              whileHover={{ scale: 1.1 }}
              whileTap={{ scale: 0.9, x: "-5px", y: "5px" }}>
              See All <i className="fa fa-arrow-right"></i>
            </motion.a>
          </center>
        </div>
      </Reveal>
      <CarHireCompanies data={carCompanies} />
      <HomePageCars />
      <section className="ftco-section">
        <div className="container">
          <div className="row justify-content-center pb-4">
            <div className="col-md-12 heading-section text-center ftco-animate">
              <span className="subheading">Our Blog</span>
              <h2 className="mb-4">Recent Blogs</h2>
            </div>
          </div>
          {blogLoading ? (
            <>
              <BlogSkeleton />
            </>
          ) : blogs.length === 0 ? (
            <center>
              <div style={{width: 'fit-content'}} className="alert alert-info text-center" role="alert">
              <h1>
                <i className="fa fa-rss-square"></i>
              </h1>
              <h3> No Blogs Found</h3>
            </div>
            </center>
          ) : (
            blogs.map((item, index) => {
              return <BlogCard item={item} index={index} key={index} />;
            })
          )}
        </div>
      </section>
    </>
  );
};
export default HomePage;
